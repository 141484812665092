var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var eventmap = [];
var eventname = "";
var ron = /^on/;

for (eventname in _global) {
  if (ron.test(eventname)) {
    eventmap.push(eventname.slice(2));
  }
}

exports = eventmap;
export default exports;